import React from 'react'
import {BsGithub, BsMedium, BsLinkedin} from 'react-icons/bs'
import {FaXTwitter} from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/murat-zamir" target="_blank" rel="noopener noreferrer"><BsLinkedin/></a>
        <a href="https://github.com/MuratZamir" target="_blank" rel="noopener noreferrer"><BsGithub/></a>
        <a href="https://muratzamir.medium.com/" target="_blank" rel="noopener noreferrer"><BsMedium/></a>
        <a href="https://twitter.com/printmurat" target="_blank" rel="noopener noreferrer"><FaXTwitter/></a>
        <a href="https://www.youtube.com/channel/UCskZwaxNBNQI14G41P2tL7A" target="_blank" rel="noopener noreferrer"><IoLogoYoutube/></a>
    </div>
  )
}

export default HeaderSocials