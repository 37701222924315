// SERVICES IS Experiences

import React from 'react'
import './services.css'
import { TbPointFilled } from 'react-icons/tb'

const Services = () => {
  return (
    <section id='experiences'>
      <h5 className='fuzuli'>What I offer</h5>
      <h2>cat /etc/experiences</h2>

      <div className="container services__container">
        {/* Technical Support Engineer - Verkada */}
        <article className="service">
          <div className="service__head">
            <h3>Technical Support Engineer - Cloud Security @ Verkada</h3>
          </div>

          <ul className='service__list'>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Providing in-depth technical troubleshooting of the entire Verkada platform including network, hardware, software, and electrical issues</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Collaborating with Engineering teams to identify security and application bugs, optimizing physical device security performance</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Testing new products for further enhancements and providing feedback to Product Specialists</p>
            </li>
          </ul>
        </article>


        {/* SECURITY ENGINEER - AMAZON */}
        <article className="service">
          <div className="service__head">
            <h3>Security Engineer @ Amazon.com, Inc</h3>
          </div>

          <ul className='service__list'>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Architected and deployed a robust full-stack AWS infrastructure for a passive vulnerability scanner</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Achieved a 90% efficiency gain in scanner operations by transforming a CLI-based application into an AWS-native web application</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Implemented Amazon’s proprietary authentication system via OAuth SSO and AWS IAM, enhancing the security and compliance of the vulnerability scanner</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Enhanced the vulnerability scanner’s capability by integrating a suite of advanced passive security assessments and threat intelligence features</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Actively attended daily stand-up meetings to discuss business decisions and assess third party applicaton's security postures by various techniques</p>
            </li>
          </ul>
        </article>
        
        {/* NETWORK ENGINEER - ITIS */}
        <article className="service">
          <div className="service__head">
            <h3>Infrastructure & Network Engineer @ Purdue ITIS </h3>
          </div>

          <ul className='service__list'>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Contributed to a real-life cyberattack remediation and recovery phase by conducting malware scanning on 300+ infected external drives </p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Kept logs of every steps involved in the incident recovery phase and reported the status updates to the head of IT department</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Re-imaged infected computers to desired Operation System, connected them to current Active Directory domain</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Installed, configured, and troubleshooted network equipment such as Cisco Switches, Uninterruptible Power Supplies (UPS), and WAPs</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Maintained accurate records of the telecommunication infrastructure and maintained proper connectivity throughout Purdue campus</p>
            </li>
          </ul>
        </article>

        {/* DEFENSIVE SEC OPERATOR - CYBERTAP */}
        <article className="service">
          <div className="service__head">
            <h3>Defensive Security Operations @ Purdue cyberTAP</h3>
          </div>

          <ul className='service__list'>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Monitored and analyzed live malicious network traffics using SIEM tools (IBM QRadar, Zenoss, Palo Alto Firewall)</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Enhanced defensive security operation skills including incident response frameworks, the cyber kill chain, and SOC operations</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Prepared cyber incident reports covering the impacts, attacker profile, threat hunting, recovery, remediation, prevention</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Analyzed the CIA triad (confidentiality, integrity, and availability) to determine the potential impact of the attack</p>
            </li>
            <li>
              <TbPointFilled className='service__list_icon' />
              <p>Documented cyber incident reports consisted of executive summary, attacker profile, threat hunting, recovery, remediation, and prevention</p>
            </li>
          </ul>
        </article>


      </div>
    </section>
  )
}

export default Services