import React from 'react'
import './footer.css'
import {RxInstagramLogo} from 'react-icons/rx'
import {FaXTwitter} from 'react-icons/fa6'
import {BsYoutube} from 'react-icons/bs'
import {BsGithub, BsLinkedin} from 'react-icons/bs'
import logo from '../../assets/logo.png'



const Footer = () => {
  return (
    <footer>
      <a src="#" className='footer__logo'><img src={logo} alt="" /></a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#experiences">Experiences</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#contacts">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://instagram.com/zamir.murat" target="_blank" rel="noopener noreferrer"><RxInstagramLogo/></a>
        <a href="https://twitter.com/printmurat" target="_blank" rel="noopener noreferrer"><FaXTwitter/></a>
        <a href="https://www.youtube.com/channel/UCv-T_GeS9j5dmnR-otETc9w" target="_blank" rel="noopener noreferrer"><BsYoutube/></a>
        <a href="https://github.com/MuratZamir" target="_blank" rel="noopener noreferrer"><BsGithub/></a>
        <a href="https://www.linkedin.com/in/murat-zamir" target="_blank" rel="noopener noreferrer"><BsLinkedin/></a>
      </div>
      

      <div className="footer__copyright">
        <small>&copy; Murat Zamir. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer