import React, { useState, useEffect } from 'react';
import './nav.css';
import { BiHome, BiMessageRoundedDots } from 'react-icons/bi';
import { IoPersonOutline } from 'react-icons/io5';
import { MdWorkOutline } from 'react-icons/md';
import { GoFileCode } from 'react-icons/go';
import { FiAward } from 'react-icons/fi';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  const sections = [
    '#', 
    '#about',
    '#skills',
    '#experiences',
    '#projects',
    '#contact',
  ];

  const handleScroll = () => {
    if (window.pageYOffset === 0) {
      setActiveNav('#'); // Set the first section active when at the top of the page
      return;
    }

    let index = sections.length - 1;
    while (index >= 0) {
      const section = document.querySelector(sections[index]);
      const offsetTop = section.offsetTop - 40; // Adjust this offset value as needed

      if (window.pageYOffset >= offsetTop) {
        setActiveNav(sections[index]);
        break;
      }
      index--;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav>
      {sections.map((section, index) => {
        const icons = [
          <BiHome />,
          <IoPersonOutline />,
          <FiAward />,
          <MdWorkOutline />,
          <GoFileCode />,
          <BiMessageRoundedDots />,
        ];
        return (
          <a
            key={index}
            href={section}
            onClick={() => setActiveNav(section)}
            className={activeNav === section ? 'active' : ''}
          >
            {icons[index]}
          </a>
        );
      })}
    </nav>
  );
};

export default Nav;
