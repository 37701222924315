// PORTFOLIO IS PROJECTS
import React from 'react'
import './portfolio.css'
import AWS from '../../assets/aws.png'
import AD from '../../assets/activedirectory.png'
import CCNA from '../../assets/ccna.png'
import DEVOPS from '../../assets/devops.png'
import EH from '../../assets/ethicalhacking.png'
import KEY from '../../assets/keylogger.png'
import KILL from '../../assets/killchain.png'
import MAC from '../../assets/macaddress.png'
import NET from '../../assets/network.png'
import PENTEST from '../../assets/pentest.png'
import BUGBOUNTY from '../../assets/bugbounty.png'
import API from '../../assets/api.png'
import CERTBUG from '../../assets/certificate-bugbounty.pdf'
import SEC from '../../assets/securityplus.jpg'



const Portfolio = () => {
  return (
    <section id='projects'>
      <h5 className='fuzuli'>My Recet Works</h5>
      <h2>cat /dev/projects</h2>

      <div className="container portfolio__container">

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={SEC} className='images' alt="" />
          </div>
          <h3>CompTIA Security+</h3>
          <div className="portfolio__item-cta">
            <a href="https://www.credly.com/badges/11d88690-a3d6-4620-82ff-6482c2c268e8" className='btn' target='_blank' rel="noopener noreferrer">Certificate</a>
            <a href="https://securityplus.muratzamir.net" className='btn' target='_blank' rel="noopener noreferrer">Notes</a>
          </div>
          
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={BUGBOUNTY} className='images' alt="" />
          </div>
          <h3>Practical Bug Bounty</h3>
          <div className="portfolio__item-cta">
            <a href={CERTBUG} className='btn' target='_blank' rel="noopener noreferrer">Certificate</a>
            <a href="https://veiled-cirrus-9da.notion.site/TCM-Sec-Bug-Bounty-1b91d69e69e94ec2ad71450b1ec84353" className='btn' target='_blank' rel="noopener noreferrer">Notes</a>
          </div>
          
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={API} className='images' alt="" />
          </div>
          <h3>OWASP API Security</h3>
          <div className="portfolio__item-cta">
            <a href="https://www.credly.com/badges/436db3a3-c731-4524-aff2-e53ea09b6e51/public_url" className='btn' target='_blank' rel="noopener noreferrer">Certificate</a>
          </div>
        </article>

      <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={DEVOPS} className='images' alt="" />
          </div>
          <h3>DevOps Pipeline</h3>
          <div className="portfolio__item-cta">
            <a href="https://muratzamir.medium.com/devops-pipeline-3a0dfa592848" className='btn' target='_blank' rel="noopener noreferrer">Medium</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={AWS} className='images' alt="" />
          </div>
          <h3>AWS Home Lab</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/MuratZamir/AWS-home-lab" className='btn' target='_blank'rel="noopener noreferrer">Github</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={KILL} className='images' alt="" />
          </div>
          <h3>The Cyber Kill Chain</h3>
          <div className="portfolio__item-cta">
            <a href="https://muratzamir.medium.com/the-cyber-kill-chain-20353374d89" className='btn' target='_blank'rel="noopener noreferrer">Medium</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PENTEST} className='images' alt="" />
          </div>
          <h3>Jr. Penetration Tester</h3>
          <div className="portfolio__item-cta">
            <a href="https://tryhackme.com/path/outline/jrpenetrationtester" className='btn' target='_blank'rel="noopener noreferrer">TryHackMe</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={CCNA} className='images' alt="" />
          </div>
          <h3>CCNA 200-301</h3>
          <div className="portfolio__item-cta">
            <a href="https://muratzamir.medium.com/ccna-200-301-5bac4a3f4b61" className='btn' target='_blank' rel="noopener noreferrer">Medium</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={EH} className='images' alt="" />
          </div>
          <h3>Practical Ethical Hacking</h3>
          <div className="portfolio__item-cta">
            <a href="https://academy.tcm-sec.com/p/practical-ethical-hacking-the-complete-course" className='btn' target='_blank' rel="noopener noreferrer">TCM Security</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={AD} className='images' alt="" />
          </div>
          <h3>Active Directory Home Lab</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/MuratZamir" className='btn' target='_blank' rel="noopener noreferrer">Github</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={NET} className='images' alt="" />
          </div>
          <h3>Enterprise Network Administration</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/MuratZamir" className='btn' target='_blank' rel="noopener noreferrer">Github</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={MAC} className='images' alt="" />
          </div>
          <h3>MAC Adress Changer</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/MuratZamir/MAC-address-changer" className='btn' target='_blank'rel="noopener noreferrer">Github</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={KEY} className='images' alt="" />
          </div>
          <h3>Keylogger</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/MuratZamir/Keylogger" className='btn' target='_blank' rel="noopener noreferrer">Github</a>
          </div>
        </article>
      
      </div>
    </section>
  )
}

export default Portfolio