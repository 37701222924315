import React from 'react'
import './about.css'
import IMG from '../../assets/img.png'
import { IoSchoolSharp } from 'react-icons/io5';
import { GiGreekTemple } from 'react-icons/gi';
import { AiOutlineSafetyCertificate } from "react-icons/ai";



const About = () => {
  return (
    <section id='about'>
      <h5 className='fuzuli'>Get to Know</h5>
      <h2>whoami</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={IMG} alt="About" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <IoSchoolSharp className='about__icon' />
              <h5>Purdue University</h5>
              <small>Cybersecurity Major</small>
            </article>

            <article className='about__card'>
              <GiGreekTemple className='about__icon' />
              <h5>Pi Kappa Alpha </h5>
              <small>Active Member</small>
            </article>

            <article className='about__card'>
              <AiOutlineSafetyCertificate className='about__icon' />
              <h5>Certification</h5>
              <small>CompTIA Security+ <br />OSCP (In Progress) </small>
            </article>
          </div>

          <p>
            As someone with a strong passion for cybersecurity, I am constantly enhancing my skills to stay ahead in this rapidly evolving field. Outside of my professional work, I engage in activities that boost my energy and focus, such as playing video games, reading, and staying active by regularly going to the gym.
          </p>

          <p>
            My journey into technology began in high school, where I co-founded a robotics club. This sparked my interest in coding with Arduino and exposed me to the broader applications of technology. My curiosity led me to explore the intersection of technology and security, eventually diving deeper into cybersecurity. I have since used resources like PortSwigger and HackTheBox to master penetration testing and web application security concepts.
          </p>

          <p>
            Currently pursuing a degree in cybersecurity at Purdue University, I actively attend security conferences to broaden my knowledge and connect with other enthusiasts. My hunger for learning drives me to continually seek new opportunities to develop a hacker mindset through hard work and dedication.
          </p>



        </div>
      </div>
    </section>
  )
}

export default About